/* eslint-disable */
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import { Home, Login, Register, Sorteo, Promotores, Cartones, Faqs, Carrito, Especial } from './pages'
 
 import Redireccion from './pages/cartones/Redireccion'
// import Especial from './pages/cartones/Especial'
import ReactGA from 'react-ga'
ReactGA.initialize('G-75FQ87LFHC')
const App = () => {
  return (<BrowserRouter>
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="sorteo" element={<Sorteo />} />
        <Route path="promotores" element={<Promotores />} />
        <Route path="carrito" element={<Carrito />} />
        <Route path="cartones" element={<Redireccion />} />
        <Route path="comun" element={<Cartones />} />
        <Route path="faqs" element={<Faqs />} />
        <Route path="preguntas" element={<Faqs />} />
        <Route path="especial" element={<Especial />} /> 
      </Routes>
  </BrowserRouter>
  )
}
export default App
/* <Route path="especial" element={<Especial />} /> */
