/* eslint-disable */
import React, { useEffect, useState } from "react";
import api from "../../services/api";

import '../../App.css';
import './SiteLogo.css';

const SiteLogo =()=> {
    const [user, setUser] = useState();
    return (
        <div className="comp-site-logo">
            <img className="logo-site" src={'./images/min/logo_bingo_min.png'} width={90} height={40} />
            <span className="title-site"></span>
        </div>
      
    );
  }
  
  export default SiteLogo;