import axios from 'axios'
const api = axios.create({
  baseURL: 'https://bingove.com/api/v2/'
})
export default api

// Verbo GET
// api.get(endpoint)

// Verbo POST
// api.post(endpoint, dados)

// Verbo DELETE
// api.delete(endpoint, dados)

// Verbo PUT
// api.put(endpoint, dados)
