/* global IntersectionObserver */
import React, { useEffect, useState, useRef } from 'react'
import '../../App.css'
import './Carton.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

function CartonEspecial (props) {
  const {
    Bolas,
    CartonesSaved,
    SaveCarton,
    Cartones,
    Sorteo,
    Order,
    FooterName,
    HideName,
    noDelete,
    marcaAgua,
    listMode,
    Ribbon
  } = props
  const [items, setItems] = useState([])
  const listRef = useRef(null)
  const batchSize = 20 // Tamaño del lote
  // Observador de intersección para detectar cuando el final de la lista es visible
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        cargarMasItems()
      }
    }, { threshold: 1 })

    if (listRef.current) {
      observer.observe(listRef.current)
    }

    // Limpiar observador cuando el componente se desmonta
    return () => {
      if (listRef.current) {
        observer.unobserve(listRef.current)
      }
    }
  }, [items]) // Observar cambios en los items para volver a adjuntar el observador

  // Función para cargar más elementos en la lista
  const cargarMasItems = () => {
    // setLoading(true)
    // Simulación de carga de datos (aquí deberías cargar los datos reales)
    setTimeout(() => {
      const newItems = []
      for (let i = 0; i < batchSize; i++) {
        newItems.push({ id: items.length + i + 1 })
      }
      setItems(prevItems => [...prevItems, ...newItems])
    // setLoading(false)
    }, 1000) // Simulación de retardo de carga
  }

  const LoadCartones = () => {
    const SortCartones = Cartones.sort((a, b) => {
      const ItemA = a.serial.replaceAll(Sorteo, '')
      const ItemB = b.serial.replaceAll(Sorteo, '')
      if (Order) {
        if (Order === 'asc') {
          return parseInt(ItemA) - parseInt(ItemB)
        }
        return parseInt(ItemB) - parseInt(ItemA)
      }
      return parseInt(ItemB) - parseInt(ItemA)
    })
    return SortCartones?.map((e, index) => {
      return (
                <CartonesAll index={index} datos={ { serial: e?.serial.replace(Sorteo, ''), dueno: e?.nombre_cliente, numeros: e?.datos }}
                    key={'carton-serial-' + e?.serial + Math.random(1000000000005465)}/>
      )
    })
  }

  const DeleteCarton = (nro) => {
    SaveCarton(CartonesSaved.filter((a) => parseInt(a) !== parseInt(nro)))
  }
  const isWinner = (statusBolas) => {
    let winner = true
    const Lleno = statusBolas.filter((a) => a === false)
    if (Lleno.length > 0) winner = false
    return winner
  }
  const CartonNew = ({ showPaid, footerName, datos, hideLogo, index }) => {
    let Formateado = datos?.numeros.replaceAll('[', '')
    Formateado = Formateado.replaceAll(']', '')
    const statusBolas = Array(25).fill(false)
    const numeros = Formateado.split(',')
    const nro = datos?.serial
    const nroCarton = nro.replaceAll(Sorteo, '')
    const propietario = datos?.dueno
    const altura = hideLogo ? '230px' : '230px'
    const lastBall = Bolas[Bolas?.length - 1]
    if (Bolas && Bolas?.length > 0) {
      Bolas.foeach((bolita) => {
        numeros.foeach((numero, index) => {
          if (parseInt(bolita) === parseInt(numero)) {
            statusBolas[index] = true
          }
          if (index === 12)statusBolas[index] = true
        })
      })
    }
    const winner = isWinner(statusBolas)
    const isWaterMark = marcaAgua ? 'carton_container_especial marcaAgua' : 'carton_container_especial'
    const claseContainerCss = winner ? 'carton_container_winner' : isWaterMark
    const [selected] = useState(false)

    return (<>
        <div className={listMode ? 'carton_individual  list-mode item' : 'carton_individual  icon-mode item'} >
            <div className={claseContainerCss} style={{ height: altura }}>
                {
                    !hideLogo &&
                    <div>
                        <img src={'./images/especial/logo-bingove-especial.png'} height={120} width={100}/>
                    </div>
                }
                {
                    Ribbon &&
                    <div onClick={() => {}} data-serial={nro} data-selected={selected[0]} className={'carton_ribbon'}>
                        <span className={`${Ribbon?.color}`}><b> {Ribbon?.texto} </b></span>
                    </div>
                }
                <div className='carton_head bg-black text-sm'>
                    <span>Carton {nroCarton}</span>
                    {
                        !noDelete &&
                        <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => DeleteCarton(datos.serial)}
                        />
                    }
                </div>
                <div className='carton_sorteo' >
                    <span className='carton_sorteo_span'>
                        {footerName ? Sorteo : propietario}
                    </span>
                </div>
                {
                    !listMode &&
                    <div className='carton_content_grid'>
                    {
                        numeros.map((numero, index) => {
                          if (index === 12) {
                            return (<span className='carton_number_free' key={'carton-tabla-' + index}>
                                    LIBRE
                                </span>)
                          }
                          if (winner) {
                            return (<span className='carton_number_winner' key={'carton-tabla-' + index}>
                                    {numero}
                                </span>)
                          }
                          if (parseInt(numero) === parseInt(lastBall)) {
                            return (<span className='carton_number_active last_ball' key={'carton-tabla-' + index}>
                                    {numero}
                                </span>)
                          }
                          if (statusBolas[index] === true) {
                            return (<span className='carton_number_active' key={'carton-tabla-' + index}>
                                    {numero}
                                </span>)
                          } else {
                            return (<span className='carton_number_normal' key={'carton-tabla-' + index}>
                                    {numero}
                            </span>)
                          }
                        })
                    }
                    </div>
                }
                <div>
                </div>
            </div>
            {
                footerName &&
                !HideName &&
                <div className='carton_footer' >
                    <span>{propietario}</span>
                </div>
            }
        </div>
    </>)
  }
  const CartonesAll = ({ datos, index }) => {
    return <CartonNew index={index} datos={datos} footerName={FooterName} hideLogo={false}/>
  }
  return (<>
    {LoadCartones()}
  </>)
}

export default CartonEspecial
