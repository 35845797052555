/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState, version } from "react";
import api from "../../services/api";
import {Header,Footer,Youtube,Carton,Tablero,Chat,Loader} from '../../components';
import './Cartones.css';
import { isMobile } from "react-device-detect";
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Button } from "react-bootstrap";
import {Link } from 'react-router-dom';

function Redireccion() {
    const location = useLocation();  
    const Consola = (value) =>  console.log(value)

    //todo para  configuraciones
    const [configs, setConfigs] = useState([]);
    const [link, setLink] = useState();
    const [vendidos,setVendidos] = useState();
    const [TotalRepartir, setTotalRepartir] = useState(0)
    const [sorteoActual, setSorteoActual] = useState();
    const [historial, setHistorial] = useState(false);
    const [PremiosLittle, setPremiosLittle] = useState(0)
    const [PremiosBig, setPremiosBig] = useState(0)
    const [videoHome1, setVideoHome1] = useState({})
    const [videoHome2, setVideoHome2] = useState({})
    const [videoUltimo, setVideoUltimo] = useState({})
    const [publicidad, setPublicidad] = useState(0)
    const [fecha, setFecha] = useState();
    const [dia, setDia] = useState();
    const [hora, setHora] = useState();
    const [rondas, setRondas] = useState(1);
    const [precioCarton, setPrecioCarton] = useState(1);
    const [ventasStatus, setVentasStatus] = useState(false);
    const [promotorStatus, setPromotorStatus] = useState(false);
    const [promotorEmpleo, setPromotorEmpleo] = useState(false); 
    const [adminStatus, setAdminStatus] = useState(false);
    const [tipoSorteo, setTipoSorteo] = useState("");
    const [linkCartonesNormal, setLinkCartonesNormal] = useState("");
    const [linkCartonesEspecial, setLinkCartonesEspecial] = useState("");
    //####################### 
   
    const input_add = useRef(null)
 
    //ESPECIAL
    const [PremiosLittleEspecial, setPremiosLittleEspecial] = useState(0.00)
    const [PremiosBigEspecial, setPremiosBigEspecial] = useState(0.00)
    const [rondasEspecial, setRondasEspecial] = useState(1);
    const [publicidadEspecial, setPublicidadEspecial] = useState(0.00)
    const [sorteoEspecial, setSorteoEspecial] = useState();
    const [vendidosEspecial,setVendidosEspecia] = useState(0);
    const [TotalRepartirEspecial, setTotalRepartirEspecial] = useState(0.00)
    const [fechaEspecial, setFechaEspecial] = useState();
    
    const GetAllConfigs = async () =>{
        await  api.get('/read/configs')
         .then((response) =>{ 
             if(response?.data?.content.length > 0){
                 const TempConfigs = response?.data?.content
                 setConfigs(TempConfigs);
                 TempConfigs.map((item)=>{               
                     const {id,tipo,valor,extra} = item     
                     switch(tipo){ 
                         case 'sorteo_actual':  
                             setSorteoActual(valor) 
                             break;
                         case 'sorteo_especial':  
                             setSorteoEspecial(valor) 
                             break;
                         case 'sorteo_youtube':  
                             setLink(valor)
                             break; 
                         case 'sorteo_fecha':  
                             setFecha(valor)
                             break;
                         case 'sorteo_fecha_especial':  
                             setFechaEspecial(valor)
                             break;
                         case 'sorteo_hora':  
                             setHora(valor)
                             break;
                         case 'sorteo_dia':  
                             setDia(valor)
                             break;
                         case 'ventas_status':  
                             setVentasStatus(valor=='1')
                             break;
                         case 'promotores_status':  
                             setPromotorStatus(valor=='1')
                             break;
                         case 'administradores_status':  
                             setAdminStatus(valor=='1')
                             break;
                         case 'sorteo_rondas':  
                             setRondas(valor)
                             break;
                         case 'sorteo_rondas_especial':  
                             setRondasEspecial(valor)
                             break;
                         case 'premio_base_small':  
                             setPremiosLittle(parseFloat(valor))
                             break;
                         case 'premio_base_big':  
                             setPremiosBig(parseFloat(valor))
                             break;
                         case 'premio_base_total':  
                             setTotalRepartir(parseFloat(valor))
                             break; 
                         case 'premio_base_small_especial':  
                             setPremiosLittleEspecial(parseFloat(valor))
                             break;
                         case 'premio_base_big_especial':  
                             setPremiosBigEspecial(parseFloat(valor))
                             break;
                         case 'premio_base_total_especial':  
                             setTotalRepartirEspecial(parseFloat(valor))
                             break; 
                         case 'cartones_precio':  
                             setPrecioCarton(parseFloat(valor));
                             break;
                         case 'promotores_empleo':  
                             setPromotorEmpleo(valor=='1')
                             break; 
                         case 'video_home_1':  
                             setVideoHome1(item)
                             break; 
                         case 'video_home_2':  
                             setVideoHome2(item)
                             break; 
                         case 'video_ultimo_sorteo':  
                             setVideoUltimo(item)
                             break;
                         case 'cartones_vendidos':  
                             setVendidos(parseInt(valor))
                             break;
                         case 'precio_publicidad':  
                             setPublicidad(parseInt(valor))
                             break;
                         case 'home_historial':  
                             setHistorial(valor=='1')
                             break;
                        case 'tipo_sorteo_activo':  
                             setTipoSorteo(valor)
                             break;
                        case 'link_cartones_normal':  
                            setLinkCartonesNormal(valor)
                            break;
                        case 'link_cartones_especial':  
                            setLinkCartonesEspecial(valor)
                            break;
                             //cartones_vendidos
                         default :  break;
                     }  
 
 
                 })
             } 
          })
       .catch((err) => {
           console.error("ops! ocorreu um erro" + err);
       });
       //updateTotalRepartir()
     }
 

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);

    useEffect(()=>{GetAllConfigs()},[]) 
     
     
    useEffect(()=>{ 
        if(tipoSorteo=="normal"){
            window.location.href = linkCartonesNormal
        } else if(tipoSorteo=="especial"){
            window.location.href = linkCartonesEspecial
        } else if(tipoSorteo=="dual"){
            //se queda en esta screen
        } 
    },[tipoSorteo,linkCartonesNormal,linkCartonesEspecial])

    return (
        <div className="pages-cartones flex-1"  > 
            <Header noLogin={true} /> 
            <div className="page-content flex-1 flex h-full mt-20 mb-20"  >
                {
                tipoSorteo == "dual" &&  
                <div className="flex flex-wrap  row-principal cartones-row flex-1 h-full gap-10 items-center justify-center">
                    <h2 style={{color:"white"}}>
                        Cual sorteo quieres comprar?
                    </h2>
                    <Link to={linkCartonesNormal}>
                        <Button style={{background:"purple",color:"white"}}> 
                            <span>SORTEO COMUN #{sorteoActual?.[0]}{sorteoActual?.[1]}{sorteoActual?.[2]}{sorteoActual?.[3]}{sorteoActual?.[4]} </span>
                            <br />
                            <span>Domingo {fecha}  {hora}</span>
                            <br />
                            <span>PRECIO 1$ / Carton</span>
                        </Button> 
                    </Link>
                    <a href={linkCartonesEspecial}>
                        <Button className="flex  flex-col" style={{background:"gold",color:"black",fontWeight:"bold"}}> 
                            <span>ESPECIAL DE LAS MADRES #E0055</span>
                            <br />
                            <span>Domingo {fechaEspecial} {hora}</span>
                            <br />
                            <span>PRECIO 3$ / Carton</span>
                        </Button>
                    </a>
                </div>
                }
                {tipoSorteo != "dual" &&  
                 <div className="flex flex-wrap  row-principal cartones-row flex-1 h-full gap-10 items-center justify-center">
                    <h2 style={{color:"white"}}>
                        Espera un momento seras redireccionado a los cartones disponibles.
                    </h2> 
                </div>
                } 
            </div>
            <Footer noLogin={true} />
        </div>
      
    );
  }
  export default Redireccion;
