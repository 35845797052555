/* eslint-disable */
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import '../../App.css';
function Loader() {
    
  return ( 
    <div className="loader-new">
      <img src={'./images/min/logo_bingo_min.png'} alt=""  height={50} width={"auto"}/> 
    </div>
    );
}
  
export default Loader;