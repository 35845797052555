/* eslint-disable */
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import logo from '../../logo.svg';
import '../../App.css';
import './header.css';
import {Link } from 'react-router-dom';
import {SiteLogo,SearchBar} from '../../components';
import { faHouse,faBell,faRandom,faPhone} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from 'react-device-detect';
function Header(props) {
    const {noLogin,loginMode,registerMode,MenuIcons,empleo} = props
    const [user, setUser] = useState();
    const MenuMobile = ()=>{
        return (<>
            <nav className="flex gap-[10px] no-underline w-6/12">
                 
                <Link key={'link002'} className="main-menu-link no-underline" to="/cartones" > 
                    <span>Cartones</span>
                </Link>
                <Link key={'link003'} className="main-menu-link no-underline" to="/promotores" >                   
                    <span>Promotores</span>
                </Link>
            </nav>
            </>
            );
        return (<></>)
        return (<>
        <nav className="menu-link-header-mobile">
            <Link key={'link001'} className="main-menu-link" to="/" >
                
                <span>Inicio</span>
            </Link>
            <Link key={'link002'} className="main-menu-link" to="/sorteo" >
                
                <span>Sorteo</span>
            </Link>
            <Link key={'link003'} className="main-menu-link" to="/promotores" >
               
                <span>Promotores</span>
            </Link>
        </nav>
        </>
        );
        
    }
    const Menu = ()=>{
                
            return noLogin?(<>
            <nav className="flex gap-[10px] lg:w-4/12">
                <Link key={'link001'} className="main-menu-link" to="/" >
                    {
                        MenuIcons 
                        && 
                        <FontAwesomeIcon icon={faHouse} />
                    }
                    <span><b>Inicio</b> </span>
                </Link>
                <Link key={'link002'} className="main-menu-link" to="/cartones" >
                    {
                        MenuIcons 
                        && 
                        <FontAwesomeIcon icon={faRandom} />
                    }
                    <span><b>Cartones</b> </span>
                </Link>
                <Link key={'link003'} className="main-menu-link" to="/promotores" >
                    {
                        MenuIcons 
                        && 
                        <FontAwesomeIcon icon={faPhone} />
                    }
                    <span><b>Promotores</b> </span>
                </Link>
                <Link key={'link004'} className="main-menu-link" to="/sorteo" >
                    {
                        MenuIcons 
                        && 
                        <FontAwesomeIcon icon={faRandom} />
                    }
                    <span><b>Sorteo</b> </span>
                </Link>
                {
                    empleo &&                 
                    <Link key={'link005'} className="main-menu-link" to="/empleo" >
                        {
                            MenuIcons 
                            && 
                            <FontAwesomeIcon icon={faRandom} />
                        }
                        <span><b>Empleo</b> </span>
                    </Link>
                }
                <Link key={'link006'} className="main-menu-link" to="/faqs" >
                    {
                        MenuIcons 
                        && 
                        <FontAwesomeIcon icon={faRandom} />
                    }
                    <span><b>Faqs</b> </span>
                </Link>
            </nav>
            </>
        ):'';        
    }

    const Logued = ()=>{
        if(noLogin){
            return(<>
            <div className="div-right-header">
                <SearchBar /> <br />
                <FontAwesomeIcon className="icon-notification" icon={faBell} />
                <br />
                <div className="div-login">
                    <Link key={'link004'} className="main-menu-link link-login" to="/Login"  >
                        <span>LOGIN</span>
                    </Link>
                    <span className="link-separator">|</span>
                    <Link key={'link005'} className="main-menu-link link-register" to="/Register"  >
                        <span>REGISTRAR</span>
                    </Link>
                </div>
            </div>
            </>)
        }
    }
    const LoginStatus = ()=>{
       
        if(loginMode){
            return(<>
            <div className="div-right-header">
                <div className="div-login">
                <Link key={'link004'} className="main-menu-link link-login" to="/"  >
                        <span>VOLVER A INICIO</span>
                    </Link>
                    <span className="link-separator">|</span>
                    <Link key={'link005'} className="main-menu-link link-register" to="/Register"  >
                        <span>REGISTRAR</span>
                    </Link>
                </div>
            </div>
            </>)
        }
        if(registerMode){
            return(<>
            <div className="div-right-header">
                <div className="div-login">
                    <Link key={'link004'} className="main-menu-link link-login" to="/"  >
                        <span>VOLVER A INICIO</span>
                    </Link>
                    <span className="link-separator">|</span>
                    <Link key={'link005'} className="main-menu-link link-login" to="/Login"  >
                        <span>LOGIN</span>
                    </Link>
                </div>
            </div>
            </>)
        }
    }

    return (
        <div  className="flex justify-between h-[50px] bg-[purple] z-[10000] right-[0] left-[0] fixed lg:relative  w-full"  >
            <Link key={'link000'} className="main-menu-link link-logo w-1/3 lg:w-2/12" to="/" >
                <SiteLogo />
            </Link>
            {isMobile?<MenuMobile />:<Menu />} 
        </div>
      
    );
  }
  
  export default Header;