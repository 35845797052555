/* eslint-disable */
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import '../../App.css';
import Iframe from 'react-iframe'
import { isMobile } from "react-device-detect";

function Youtube({datos,funcion,link,expand}) {
    let width_video = isMobile? 400 : 620
    let height_video = isMobile? 200: 350
    if(expand){
        width_video = "920"
        height_video = "500"
    }
    return (
        <div  className="z-[10000] flex justify-center "         
            >
            <Iframe 
                src= {"https://www.youtube.com/embed/"+link}
                width={width_video}
                height={height_video}
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowfullscreen               
             />
        </div>
      
    );
  }
  
  export default Youtube;